import config from 'constants/config'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
// import { LUXURY_ESCAPES } from 'constants/brands'
import { internalLeTestAccountIds } from './loyaltyWhiteList'

export function getIsLuxLoyaltyLoadTestEnabled(_: App.State): boolean {
  // const memberId = state.auth.account.memberId
  return false
  // return !!(
  //   config.BRAND === LUXURY_ESCAPES &&
  //   // Exclude internal LE test accounts from load test
  //   (!memberId || !internalLeTestAccountIds.has(memberId)) &&
  //   state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxLoyaltyLoadTest]
  // )
}

export function getHasLuxLoyaltyAccount(state: App.State): boolean {
  return !!(getIsLuxLoyaltyEnabled(state) && state.luxLoyalty.account.data)
}

export function getIsLuxLoyaltyEnabled(state: App.State): boolean {
  if (config.LUX_LOYALTY_ENABLED && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxLoyaltyFeatureToggle]) {
    return true
  }

  if (!state.auth.account.memberId || state.geo.currentRegionCode !== 'AU') {
    return false
  }

  if (internalLeTestAccountIds.has(state.auth.account.memberId)) {
    return true
  }

  // NOTE: This is turned off temporary as of 9/4/2025
  // To be turned back on once we want to start enroling special gold members
  // if (
  //   (state.auth.account.isSpoofed && goldMemberWhiteListIds.has(state.auth.account.memberId)) ||
  //   (goldMemberWhiteListIds.has(state.auth.account.memberId) && state.luxLoyalty.account.data)
  // ) {
  //   return true
  // }

  return false
}
